
<div fxLayout="column" fxLayoutAlign="center center" *ngIf="item.customField23 ==1 && item.customField25 != 6">
  <calsoft-editor-text [data]="data" [fontSize]="item.customField18" [text]="item?.title"
    [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
  </calsoft-editor-text>
</div>
<div *ngIf="item.customField25 !=3 && item.customField25 !=4 && item.customField25 !=5 && item.customField25 !=6">

  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <div *ngFor="let link of item.images" fxFlex="100" fxFlex.xs="100" [ngStyle]="{
           'margin-top': item.mobileGapBetweenImage, 
           'margin-bottom': item.mobileGapBetweenImage
         }" [ngStyle.gt-md]="{
           'margin-top': item.gapBetweenImage, 
           'margin-bottom': item.gapBetweenImage
         }">

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [style.minHeight]="'50px'"
        [class.expanded]="panelOpenState">
        <mat-expansion-panel-header [ngStyle.lt-md]="{
          'padding-bottom': '20px', 
          'padding-top': '20px'
        }">
          <mat-panel-title>


            <span class="truncate-text" [innerHTML]="link.customField15"  [ngStyle.gt-md]="{'font-size': item.customField38 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField15
            }"></span>
            <!-- <mat-icon>{{ panelOpenState ? 'remove_circle' : 'add_circle' }}</mat-icon> -->
          </mat-panel-title>
        </mat-expansion-panel-header>

        <calsoft-editor-text [data]="data" class="panel-content" [text]="link.customField16" [ngStyle.gt-md]="{'font-size': item.customField39 }"
        [ngStyle.lt-md]="{
            
            'font-size': item.customField40
        }">
        </calsoft-editor-text>
      </mat-expansion-panel>
    </div>
  </div>


</div>

<div *ngIf="item.customField25 == 3">

  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start start">
    <div fxFlex="1 1 calc(50% - 20px)" *ngFor="let link of item.images" style="padding: 10px">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="truncate-text" [innerHTML]="link.customField15" [ngStyle.gt-md]="{'font-size': item.customField38 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField15
            }"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        
        <!-- Replaced calsoft-editor-text with p tag -->
        <span [innerHTML]="link.customField16" [ngStyle.gt-md]="{'font-size': item.customField39 }"
        [ngStyle.lt-md]="{
            
            'font-size': item.customField40
        }"></span>
        
      </mat-expansion-panel>
    </div>
  </div>
  
</div>

<div  *ngIf="item.customField25 == 4">

  <div [fxLayout.gt-md]="item.customField3=='left'?'row':'row-reverse'" fxLayout="row" fxLayout.xs="column"
    fxLayoutGap.xs="16px">
    <div fxFlex="50" fxFlex.xs="100">
      <img onerror="this.src='';" width="100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
        [src]="serverPath2 + faq[index].customField6" [alt]="faq[index].customField6"
        style="border-top-right-radius: 20px;border-bottom-left-radius: 20px; " />
      <div [ngClass]="faq[index].customField6.length > 0 ? 'bottom-content' : 'bottom-content1'">
        <!-- <h4>{{faq[index].customField15}}das</h4> -->
        <span fxFlex="100" class="truncate-text" [innerHTML]="faq[index].customField15" [ngStyle.gt-md]="{'font-size': item.customField38 }"
        [ngStyle.lt-md]="{
            
            'font-size': item.customField15
        }"></span>
      </div>
    </div>

    <div fxFlex="50" fxFlex.xs="100">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center">
        <div fxFlex="100" *ngFor="let link of item.images; let i = index"
          [ngStyle]="{'margin': item.mobileGapBetweenImage}" [ngStyle.gt-md]="{'margin': item.gapBetweenImage}">

          <mat-expansion-panel [hideToggle]="true" [expanded]="i == index" (closed)="panelOpenState = false"
            (opened)="onPanelOpen(link.customField15, i)">
            <mat-expansion-panel-header>
              <mat-panel-title fxFlex="100" fxLayoutAlign="space-between center">
                <span fxFlex="100" class="truncate-text" [innerHTML]="link.customField15" [ngStyle.gt-md]="{'font-size': item.customField38 }"
                [ngStyle.lt-md]="{
                    
                    'font-size': item.customField15
                }"></span>
                <mat-icon>{{ panelOpenState && i == index ? 'remove_circle' : 'add_circle' }}</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <calsoft-editor-text [data]="data" [text]="link.customField16" [ngStyle.gt-md]="{'font-size': item.customField39 }"
            [ngStyle.lt-md]="{
                
                'font-size': item.customField40
            }">
            </calsoft-editor-text>
          </mat-expansion-panel>

        </div>
      </div>
    </div>

  </div>
</div>

<div *ngIf="item.customField25 == 5">
  <div *ngFor="let link of item.images; let i = index"
  [ngStyle]="{'margin-top': item.mobileGapBetweenImage, 'margin-bottom': item.mobileGapBetweenImage }"
  [ngStyle.gt-md]="{'margin-top': item.gapBetweenImage, 'margin-bottom': item.gapBetweenImage}">

  <mat-expansion-panel [hideToggle]="true" [expanded]="i === expandedIndex" 
    (closed)="onPanelClose(i)"
    (opened)="onPanelOpen(link.customField15, i)" 
    [ngClass]="{ 'redius': i === expandedIndex }" class="redius">

    <mat-expansion-panel-header
      [ngStyle]="{ 'background-color': i === expandedIndex ? item.customField26 : 'inherit' }" 
      class="header-height">

      <mat-panel-title fxLayoutAlign="space-between center" 
        [ngStyle]="{
          'color': i === expandedIndex ? 'white' : 'black',
          'font-size': item.customField18
        }" 
        [ngStyle.lt-md]="{
          'font-size': item.customField17,
          'color': i === expandedIndex ? 'white' : 'black'
        }">

        <calsoft-editor-text [data]="data" fxFlex="100" [text]="link.customField15" 
        [ngStyle.gt-md]="{'font-size': item.customField38 }" 
        [ngStyle.lt-md]="{
            'font-size': item.customField15
        }" [align]="item.customField34">
        </calsoft-editor-text>
        <mat-icon [ngStyle]="{ 'color': i === expandedIndex ? 'white' : item.customField26 }">
          {{ i === expandedIndex ? 'remove_circle' : 'add_circle' }}
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <calsoft-editor-text style="padding-top: 10px;" [data]="data" fxLayoutAlign="start center" [text]="link.customField16" 
    [ngStyle.gt-md]="{'font-size': item.customField39 }"
    [ngStyle.lt-md]="{
        'font-size': item.customField40
    }">
    </calsoft-editor-text>
  </mat-expansion-panel>
</div>

</div>

<div *ngIf="item.customField25 == 6">
  <div fxLayout="row"
    style="background-color: black;padding: 8px;border-top-left-radius: 5px;border-top-right-radius: 5px;;">
    <div fxFlex="95" fxFlex.xs="100">
      <p style="padding-left: 5px;">
        <calsoft-editor-text [data]="data" [text]="item.title" [ngStyle.gt-md]="item.customField18" 
        [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
        </calsoft-editor-text>
      </p>

    </div>
    <div fxFlex="5" fxFlex.xs="100" fxLayoutAlign="end end" style="margin-right: 5px;">
      <mat-icon (click)="toggleSection()" matSuffix class="toggleicon"
        *ngIf="!item_visible">keyboard_arrow_right</mat-icon>
      <mat-icon (click)="toggleSection()" class="toggleicon" *ngIf="item_visible">keyboard_arrow_down</mat-icon>
    </div>
  </div>

  <div *ngIf="item_visible">
    <div *ngFor="let itemz of item.images; let i = index">
      <div fxLayout="row">
        <div [fxFlex]="itemz.customField17 || 50" fxFlex.xs="100" style="border: 1px solid lightgray;padding: 8px;" fxLayoutAlign="start start"
          [style.font-weight]="i === 0 ? 'bold' : 'normal'">
          <calsoft-editor-text  [data]="data" [text]="itemz.customField15" [ngStyle.gt-md]="{'font-size': item.customField38 }"
          [ngStyle.lt-md]="{
              
              'font-size': item.customField15
          }">
          </calsoft-editor-text>
        </div>
        <div fxFlex="100" fxFlex.xs="100" style="border: 1px solid lightgray;padding: 8px;" fxLayoutAlign="center center"
          [style.font-weight]="i === 0 ? 'bold' : 'normal'">
          <calsoft-editor-text style="font-size: 13px;" [data]="data" [text]="itemz.customField16" [ngStyle.gt-md]="{'font-size': item.customField39 }"
          [ngStyle.lt-md]="{
              
              'font-size': item.customField40
          }">
          </calsoft-editor-text>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="item.customField25 == 7" class="zoho-type-faq">
  <div class="content-81d" fxHide fxShow.gt-sm>
    <div style="    padding: 80px 120px 40px 120px;">
        <div class="header-container">
            <span>  <calsoft-editor-text [data]="data" [text]="item.title" [ngStyle.gt-md]="item.customField18" 
              [ngStyle.lt-md]="{ 'font-size': item.customField17 }">
              </calsoft-editor-text></span>

        </div>
    
        <div class="table-x73">
          <div class="column-wto lef-98j">
            <div *ngFor="let perk of item.images; let i = index"  [class.active]="selectedPerkIndex === i"  class="acc-fsx" (click)="showPerkData(i)">
              <span> <calsoft-editor-text  [data]="data" [text]="perk.customField15" [ngStyle.gt-md]="{'font-size': item.customField38 }"
                [ngStyle.lt-md]="{
                    
                    'font-size': item.customField15
                }">
                </calsoft-editor-text></span>
              <span class="line-f7j"></span>
            </div>
          </div>
      
          <div class="column-wto rig-gh3" *ngIf="clickedPerk">
            <div class="img-area-6do">
              <div class="img-6ko">
                <div class="zah-perks-pzf">
                  <img onerror="this.src='';" width="100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
                  [src]="serverPath2 + clickedPerk.customField6" [alt]="clickedPerk.customField6"
                  >
                  <!-- <img [src]="clickedPerk.customField6" alt="{{ clickedPerk.title }}" width="180" height="146"> -->
                  <span><calsoft-editor-text  [data]="data" [text]="perk.customField17" [ngStyle.gt-md]="{'font-size': item. customField30 }"
                  ></calsoft-editor-text></span>
                   
                  <span> <calsoft-editor-text  [data]="data" [text]="perk.customField16" [ngStyle.gt-md]="{'font-size': item.customField39 }"
                    [ngStyle.lt-md]="{
                        
                        'font-size': item.customField40
                    }">
                    </calsoft-editor-text></span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  
  </div>

  <div class="content-82d" fxHide fxShow.lt-sm>
    <div class="container">
      <div class="header-container">
        <span> <calsoft-editor-text [data]="data" [text]="item.title" 
          [MobfontSize]="item.customField17">
          </calsoft-editor-text></span>
      </div>
  
      <div class="table-x73">
        <div class="column-wto lef-98j">
          <!-- Loop through perks to display each perk -->
          <div *ngFor="let perk of item.images; let i = index" class="acc-fsx">
            <!-- <h4>{{ perk.title }}</h4> -->
            <span class="line-f7j"></span>
  
            <div class="img-area-6do">
              <div class="img-6ko">
                <div class="zah-perks-pzf" style="margin-bottom: 100px;text-align: center;">
                    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 25px;">
                      <img onerror="this.src='';" width="100%" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}"
                      [src]="serverPath2 + perk.customField6" [alt]="perk.customField6"
                      >
                        <!-- <img [src]="perk.image" alt="{{ perk.title }}" width="180" height="146"> -->
                    </div>
                <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 15px;">
                    <span>   <calsoft-editor-text  [data]="data" [text]="perk.customField15" 
                      [MobfontSize]="{
                          
                          'font-size': item.customField15
                      }">
                      </calsoft-editor-text></span>
                </div>
                 
                 <div fxLayout="row" fxLayoutAlign="center center">
                    <span> <calsoft-editor-text  [data]="data" [text]="perk.customField16" [ngStyle.gt-md]="{'font-size': item.customField39 }"
                      [MobfontSize]="{
                          
                          'font-size': item.customField40
                      }">
                      </calsoft-editor-text></span>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>