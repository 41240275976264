<div class="mobileheader">

    <div style="padding: 0px;" fxLayout="row" [style.margin]="mobileHeaderLogoGap" style="height: 500px;">

        <div>

            <div class="column">
                <div fxLayoutAlign="start center">
                    <div fxFlex="10%">
                        <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle"
                            mat-icon-button (click)="toggleSidebar()">
                            <i style="font-size: 22px" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
                        </button>
                        <ng-template #elseBlock>
                            <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button
                                (click)="back()">
                                <i class="material-icons ">keyboard_arrow_left</i>
                            </button>
                        </ng-template>
                    </div>

                </div>



                <div fxLayout="column" fxLayoutAlign="center center" class="col-md-10 "><br
                        class="snipcss0-5-7-10">

                    <div fxFlex class="col-md-2 snipcss0-4-4-5" fxLayoutAlign="center center">
                        <img style="width: 100%;" [src]="serverPath+'logo.png'" alt=""
                            class="intro-image fadeInLeft wow animated " id="style-Q3QGT">

                    </div>

                    <div style="padding-top: 10px;">
                        <h2 class="center-title " id="style-s5VlO">
                            {{data.headerNotificationContent}}</h2>
                        <p class="intro-content text-success  " id="style-Rz7ke">
                            {{data.headerBannerCopyCoupon}}</p>
                    </div>
                    
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex="auto">
                        <!-- Other content here, if any -->
                    </div>
                    <div fxFlex="none">
                        <calsoft-menu-iteration3 style="z-index: 1000;" [data]="data" [categoryArray]="item">
                        </calsoft-menu-iteration3>
                    </div>
                </div>




            </div>
        </div>

    </div>
</div>