import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  selector: 'calsoft-plan-compare-type12',
  templateUrl: './plan-compare-type12.component.html',
  styleUrls: ['./plan-compare-type12.component.scss']
})
export class PlanCompareType12Component {

  isChecked: boolean = false;
    pricedetailnew: any;
  
    toggleChanged() {
      console.log('Slide toggle changed. New value:', this.isChecked);
    }
  
    @Input() item: any;
    @Input() data: any;
  
    @Input() type: any= 1;
  
    constructor( private commonService: CommonService,
      private subscriptionPriceService : SubscriptionPriceService,
    ) {
  
    }
    
    ngOnInit() {   
      this.pricedetailnew = JSON.parse(this.item.customField30);
  
      let index= this.findCenterElement(this.pricedetailnew);
  
      this.selectSubscription(index.p_price_annualy, this.pricedetailnew.title2)
      
    }  
    
    findCenterElement(arr: any[]): any {
      if (arr.length === 0) return undefined;
      const middleIndex = Math.floor(arr.length / 2);
      return arr[middleIndex];
    }
  
    openInNewWindow(url: string) {
      if (url) {
        const fullURL = this.prependProtocol(url);
        this.commonService.goToLink(url)
      }
    }
  
    prependProtocol(url: string): string {
      if (!url.includes('http://') && !url.includes('https://')) {
        return 'https://' + url;
      }
      return url;
    }
  
    selectedPrice: string | null = null;
  
    selectSubscription(price: any, title: string) {
      this.selectedPrice = price;
  
      let taxPercentage = this.item.customField21;
      const isExclusive = this.item.customField22 === 'Exclusive';
  
      let totalCost = price;
      let taxAmount=0.0; 
      if (isExclusive) {
        taxAmount = (price * taxPercentage) / 100;
        totalCost = parseFloat(price) + taxAmount;
      } else {
        // taxAmount = (price*taxPercentage)/(100+taxPercentage);
  
        // totalCost = Math.abs(totalCost);
       
      }
  
      this.subscriptionPriceService.updateSubscriptionDetails(price, taxPercentage, totalCost,taxAmount , title);
    }

}
