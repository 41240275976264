<div fxLayout="row" fxLayout.lt-md="column" fxHide fxShow.gt-sm class="full-height-container">

  <div fxFlex="50" class="sticky-container">
    <div fxLayout="row" fxLayoutAlign="center" fxFlex="80" class="centered-content">
      <div fxLayout="column" class="sticky-content">
        <span class="section-title">
          <calsoft-text-editor [data]="data" [text]="item?.title" [ngStyle.gt-md]="{'font-size': item.customField33 }"
            [ngStyle.lt-md]="{ 'font-size': item.customField34 }">
          </calsoft-text-editor>
        </span>
        <span class="description">
          <calsoft-text-editor [data]="data" [text]="item?.customField20"
            [ngStyle.gt-md]="{'font-size': item.customField21 }" [ngStyle.lt-md]="{ 'font-size': item.customField22 }">
          </calsoft-text-editor>

        </span>
      </div>
    </div>
  </div>

  <div fxFlex="50" class="scroll-container">
    <div class="scroll-content">
      <div *ngFor="let section of images" class="section" fxLayout.lt-md="column">
        <div class="image-container">
          <img  [attr.height]="section.customField23" 
          [attr.width]="section.customField24"  [src]="bannerServerPath + section.customField8" [alt]="section?.alt" />
        </div>
        <div class="content-container">
          <div class="text-content">
            <span class="section-title-two">
              <calsoft-text-editor [data]="data" [text]="section.customField6"
                [ngStyle.gt-md]="{'font-size': item.customField27 }">
              </calsoft-text-editor></span>
            <span class="description">
              <calsoft-text-editor [data]="data" [text]="section.customField7"
                [ngStyle.gt-md]="{'font-size': item.customField29 }">
              </calsoft-text-editor></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="column" fxHide fxShow.lt-sm>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="80">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="90" style="padding: 0 10%;">

        <span style="text-align: center;">
           <calsoft-text-editor [data]="data" [text]="item?.title"  [MobfontSize]="item.customField34">
        </calsoft-text-editor></span>
        <span style="text-align: center;">
          <calsoft-text-editor [data]="data" [text]="item?.customField20"  [MobfontSize]="item.customField22">
          </calsoft-text-editor>
        </span>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div *ngFor="let section of images" fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="90" style="padding: 0 10%;">
        <div>
          <img  [attr.height]="section.customField25" 
          [attr.width]="section.customField26"  [src]="bannerServerPath + section.customField8" [alt]="section?.alt" />
        </div>
        <div>
          <span style="text-align: center;">   
            <calsoft-text-editor [data]="data" [text]="section?.customField6"  [MobfontSize]="item.customField28">
          </calsoft-text-editor></span>
          <span style="text-align: center;">   
            <calsoft-text-editor [data]="data" [text]="section?.customField7"  [MobfontSize]="item.customField30">
          </calsoft-text-editor></span>
        </div>
      </div>
    </div>
  </div>
</div>